<template>
  <nav class="p-4 bg-white shadow-sm sticky top-0 z-50">
    <div class="flex max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 items-center justify-between">
      <!-- Header logo -->
      <div>
        <router-link to="/">
          <img class="block lg:hidden h-8 w-auto" :src="headerLogo" />
          <img class="hidden lg:block h-12 w-auto" :src="headerLogo" />
        </router-link>
      </div>

      <!-- Mobile toggle -->
      <div class="md:hidden flex">
        <Menu v-if="loggedIn" as="div" class="ml-3 relative">
          <div>
            <MenuButton class="flex items-center text-md rounded-full">
              Hello, {{ this.userData.first_name }}
              <img class="h-8 w-8 ml-2 rounded-full object-cover square_img" :src="this.userData.profile" alt="" />
              <ChevronDownIcon class="mr-2 h-5 w-5 text-violet-200 hover:text-violet-100" aria-hidden="true" />
            </MenuButton>
          </div>
          <transition enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems
              class=" origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg p-2  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-left">
              <div class=" px-4 py-2 text-md flex items-center text-blue hover:bg-light-blue rounded-md
                                                        ">
                <UserIcon class="mr-2 h-6 w-6 text-blue hover:text-blue" aria-hidden="true" />Update Profile
              </div>
              <div @click="handleLogOut()" class=" px-4 py-2 text-md flex items-center text-red hover:bg-light-blue rounded-md
                                                        ">
                <ArrowLeftOnRectangleIcon class="mr-2 h-6 w-6 text-red hover:text-red" aria-hidden="true" />Log Out
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <button @click="drawer">
          <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
            <path fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>

      <!-- Navbar -->
      <div class="hidden md:block">
        <ul class="flex items-center space-x-2 text-md font-sans">
          <router-link active-class="text-blue border-b-2 border-blue" to="/">
            <div class="block px-4 py-2 text-md text-gray-700 pb-1">Home</div>
          </router-link>
          <router-link active-class="text-blue border-b-2 border-blue" to="/news_and_events">
            <div class=" block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md
                                                      ">
              News and Events
            </div>
          </router-link>
          <Menu as="div" class="ml-3 relative">
            <div>
              <MenuButton
                class=" flex px-4 py-2 items-center text-md focus:text-blue hover:bg-light-gray hover:text-blue rounded-md">
                Membership
                <ChevronDownIcon class="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                class=" origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-lg p-2  bg-white ring-1 ring-black ring-opacity-5 text-left">
                <router-link active-class="text-blue border-b-2 border-blue font-medium" to="/membership_benefit">
                  <div class=" block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md">
                    Membership Benefits
                  </div>
                </router-link>
                <router-link active-class="text-blue border-b-2 border-blue font-medium" to="/become_a_member">
                  <div class=" block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md">
                    Become a Member
                  </div>
                </router-link>
                <router-link active-class="text-blue border-b-2 border-blue font-medium" to="/member_directory">
                  <div class="block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md">
                    Member Directory
                  </div>
                </router-link>
              </MenuItems>
            </transition>
          </Menu>
          <router-link active-class="text-blue border-b-2 border-blue" to="/about">
            <div class="block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md">
              About Us
            </div>
          </router-link>
          <router-link active-class="text-blue border-b-2 border-blue" to="/opportunities">
            <div class="block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md">
              Opportunities
            </div>
          </router-link>
          <router-link active-class="text-blue border-b-2 border-blue" to="/contact_us">
            <div class="block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md">
              Contact Us
            </div>
          </router-link>
          <!-- user profile showed after logged in -->
          <Menu v-if="loggedIn" as="div" class="ml-3 relative">
            <div>
              <MenuButton class="flex items-center text-md rounded-full">
                Hello, {{ this.userData.first_name }}
                <img class="h-8 w-8 ml-2 rounded-full object-cover square_img" :src="this.userData.profile" alt="" />
                <ChevronDownIcon class=" ml-1 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100 " aria-hidden="true" />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                class=" origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-lg p-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-left">
                <MenuItem>
                <div @click="showUpdateProfile"
                  class=" px-4 py-2 text-md flex items-center text-blue hover:bg-light-blue rounded-md">
                  <UserIcon class="mr-2 h-6 w-6 text-blue hover:text-blue" aria-hidden="true" />Update Profile
                </div>
                </MenuItem>
                <MenuItem>
                <div @click="showResetPassword"
                  class=" px-4 py-2 text-md flex items-center text-blue hover:bg-light-blue rounded-md">
                  <KeyIcon class="mr-2 h-6 w-6 text-blue hover:text-blue" aria-hidden="true" />
                  Change Password
                </div>
                </MenuItem>
                <MenuItem>
                <div @click="handleLogOut()"
                  class=" px-4 py-2 text-md flex items-center text-red hover:bg-light-blue rounded-md">
                  <ArrowLeftOnRectangleIcon class="mr-2 h-6 w-6 text-red hover:text-red" aria-hidden="true" />Log Out
                </div>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
          <!-- log in link showed befor login in -->
          <router-link v-else to="/login">
            <div class="block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md">
              Member Login
            </div>
          </router-link>
        </ul>
      </div>

      <!-- Dark Background Transition -->
      <transition enter-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100"
        leave-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0">
        <div @keydown.esc="isOpen = false" v-show="isOpen" class="z-10 fixed inset-0 transition-opacity">
          <div @click="isOpen = false" class="absolute inset-0 bg-black opacity-50" tabindex="0"></div>
        </div>
      </transition>

      <!-- Drawer Menu -->
      <aside
        class=" p-5 transform top-0 left-0 w-64 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30"
        :class="isOpen ? 'translate-x-0' : '-translate-x-full'">
        <div class="justify-between pb-4 border-b border-light-gray">
          <div class="close">
            <button class="absolute top-0 right-0 mt-4 mr-4" @click="isOpen = false">
              <svg class="w-6 h-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                viewBox="0 0 24 24" stroke="currentColor">
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <img @click="isOpen = false" class="h-10 w-auto" src="../assets/AAA-C-Logo.jpg" />
        </div>

        <ul class="divide-y divide-light-gray font-sans text-left">
          <li>
            <router-link to="/" @click="isOpen = false" class="my-4 inline-block">Home</router-link>
          </li>
          <li>
            <router-link to="/news_and_events" @click="isOpen = false" class="my-4 inline-block">News and
              Events</router-link>
          </li>
          <Menu as="div" class="relative">
            <div class="w-full justify-between">
              <MenuButton class="flex py-4 items-center text-md">
                <div class="flex w-full justify-between">
                  <p>Membership</p>
                  <ChevronDownIcon class="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                </div>
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                class="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg p-2 bg-white ring-1 ring-black ring-opacity-5 text-left">
                <router-link to="/membership_benefit">
                  <div class="block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md">
                    Membership Benefits
                  </div>
                </router-link>
                <router-link to="/become_a_member">
                  <div class="block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md">
                    Become a Member
                  </div>
                </router-link>
                <router-link to="/member_directory">
                  <div class="block px-4 py-2 text-md text-gray-700 hover:bg-light-gray hover:text-blue rounded-md">
                    Member Directory
                  </div>
                </router-link>
              </MenuItems>
            </transition>
          </Menu>
          <li>
            <router-link to="/about" @click="isOpen = false" class="my-4 inline-block">About Us</router-link>
          </li>
          <li>
            <router-link to="/opportunities" @click="isOpen = false" class="my-4 inline-block">Opportunities</router-link>
          </li>
          <li>
            <router-link to="/contact_us" @click="isOpen = false" class="my-4 inline-block">Contact Us</router-link>
          </li>
          <li v-if="!loggedIn" class="my-4 inline-block">
            <router-link to="/login" @click="isOpen = false" class="my-4 inline-block">Member Login</router-link>
          </li>
        </ul>
      </aside>
    </div>
  </nav>
  <!-- Slide over -->
  <TransitionRoot as="template" :show="showSlideOver">
    <Dialog as="div" class="relative z-50" @close="showSlideOver = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="
                                                                            pointer-events-none
                                                                            fixed
                                                                            inset-y-0
                                                                            right-0
                                                                            flex
                                                                            max-w-full
                                                                            pl-10
                                                                          ">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0"
                  enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="
                                                                                    absolute
                                                                                    top-0
                                                                                    left-0
                                                                                    -ml-8
                                                                                    flex
                                                                                    pt-4
                                                                                    pr-2
                                                                                    sm:-ml-10 sm:pr-4
                                                                                  "></div>
                </TransitionChild>
                <div class="
                                                                                  flex
                                                                                  h-full
                                                                                  flex-col
                                                                                  overflow-y-scroll
                                                                                  bg-white
                                                                                  py-6
                                                                                  shadow-xl
                                                                                ">
                  <div class="px-4 sm:px-6 flex justify-between items-start">
                    <div>
                      <DialogTitle class="text-2xl font-medium font-sans text-blue">Update Profile</DialogTitle>
                    </div>
                    <button type="button" class="rounded-md text-white" @click="open = false">
                      <span class="sr-only">Close panel</span>
                      <XMarkIcon class="h-6 w-6 text-black" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <!-- profile image -->
                    <div @click="handleFileImport" class="group py-4 cursor-pointer">
                      <!-- hiddden image file input field that triger when profile image container clicked -->
                      <input ref="imageFileUploader" class="hidden" type="file" name="profileImage" id="profileImage"
                        @change="handleFileUpload($event)" />
                      <div class="flex justify-center">
                        <img class="
                                                                                          w-1/4
                                                                                          square_img
                                                                                          object-cover
                                                                                          rounded-full
                                                                                          mb-2
                                                                                        " :src="imageUrl" alt="" />
                      </div>
                      <p class="
                                                                                        cursor-pointer
                                                                                        text-center text-lg
                                                                                        font-sans
                                                                                        text-blue
                                                                                        group-hover:underline
                                                                                      ">
                        Change profile image
                      </p>
                    </div>
                    <div>
                      <p class="text-gray font-sans font-light text-lg mb-2">
                        About you
                      </p>
                    </div>
                    <!-- first name and last name -->
                    <div class="flex pb-4">
                      <base-text-input v-model="first_name" name="first_name" label="First Name" class="w-1/2 pr-2" />
                      <base-text-input v-model="last_name" name="last_name" label="Last Name" class="w-1/2 pl-2" />
                    </div>
                    <!-- gender and title -->
                    <div class="flex pb-4">
                      <div class="flex flex-col w-1/2 mr-2">
                        <p class="
                                                                                          text-gray
                                                                                          label-text
                                                                                          font-sans font-normal
                                                                                          mb-1
                                                                                        ">
                          Gender
                        </p>
                        <select class="border border-dark-gray rounded-xl p-3" v-model="gender">
                          <option value="">Select gender</option>
                          <option v-for="(gender, index) in genders" :key="index" :value="index">
                            <p>{{ gender }}</p>
                          </option>
                        </select>
                      </div>
                      <div class="flex flex-col w-1/2 ml-2">
                        <p class="
                                                                                          text-gray
                                                                                          label-text
                                                                                          font-sans font-normal
                                                                                          mb-1
                                                                                        ">
                          Title
                        </p>
                        <select class="border border-dark-gray rounded-xl p-3" v-model="title">
                          <option value="">Select your title</option>
                          <option v-for="(title, index) in titles" :key="index" :value="title">
                            <p>{{ title }}</p>
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- date of birth -->
                    <base-text-input v-model="dob" type="date" name="dob" label="Date of Birht" class="pb-5" />
                    <!-- citizenship -->
                    <div class="flex flex-col w-full pb-5">
                      <p class="text-gray label-text font-sans font-normal mb-">
                        Citizenship
                      </p>
                      <select class="border border-dark-gray rounded-xl p-3" v-model="citizenship">
                        <option value="">Select your citizenship</option>
                        <option v-for="(citizenship, index) in citizenships" :key="index" :value="citizenship">
                          <p>{{ citizenship }}</p>
                        </option>
                      </select>
                    </div>
                    <!-- phone number -->
                    <base-text-input v-model="phoneNumber" name="phoneNumber" label="Phone Number" class="pb-5" />
                    <!-- buss phone number -->
                    <base-text-input v-model="bussPhoneNumber" name="bussPhoneNumber" label="Business Phone (Alternative)"
                      class="pb-5" />
                    <!-- email -->
                    <base-text-input v-model="email" name="email" label="Email" class="pb-5" />
                    <!-- buss email -->
                    <base-text-input v-model="bussEmail" name="bussEmail" label="Business Email (Alternative)"
                      class="pb-5" />
                    <!-- address -->
                    <base-text-input v-model="address" name="address" label="Address" class="pb-5" />
                    <!-- work place -->
                    <base-text-input v-model="workPlace" name="workPlace" label="Name of Oraganization/Workplace"
                      class="pb-5" />
                    <!-- job tile -->
                    <base-text-input v-model="jobTitle" name="jobTitle" label="Job Title" class="pb-5" />
                    <!-- orther Job -->
                    <base-text-input v-model="otherJob" name="otherJob" label="Other Job (if any)" class="pb-5" />
                    <div>
                      <p class="text-gray font-sans font-light text-lg mb-2">
                        Social Media
                      </p>
                    </div>
                    <!-- facebook -->
                    <base-text-input v-model="social.facebook" name="facebook" label="Facebook" class="pb-5" />
                    <!-- orther Job -->
                    <base-text-input v-model="social.linkedin" name="lnkedin" label="linkedIn" class="pb-5" />
                    <div>
                      <p class="text-gray font-sans font-light text-lg mb-2">
                        Education Background
                      </p>
                    </div>
                    <!-- university country -->
                    <base-text-input v-model="countryUniv" name="contryUniv" label="Country of University Attended"
                      class="pb-5" />
                    <!-- university name -->
                    <base-text-input v-model="UnivName" name="UnivName" label="Name of University Attended"
                      class="pb-5" />
                    <!-- Qualification -->
                    <div class="flex flex-col w-full pb-5">
                      <p class="text-gray label-text font-sans font-normal mb-">
                        Qualification
                      </p>
                      <select class="border border-dark-gray rounded-xl p-3" v-model="qulification">
                        <option value="">Select your qualification</option>
                        <option v-for="(qualification, index) in qulifications" :key="index" :value="qualification">
                          <p>{{ qualification }}</p>
                        </option>
                      </select>
                    </div>
                    <!-- major name -->
                    <base-text-input v-model="major" name="major" label="Major" class="pb-5" />
                    <!-- date of Commencement -->
                    <base-text-input v-model="dateOfCommencement" type="date" name="dateofCommencement"
                      label="Date of Commencement" class="pb-5" />
                    <!-- date of complition -->
                    <base-text-input v-model="dateOfCompletion" type="date" name="dateOfCompletion"
                      label="Date of Completion" class="pb-5" />
                    <!-- Funding -->
                    <div class="flex flex-col w-full pb-5">
                      <p class="text-gray label-text font-sans font-normal mb-">
                        Funding Scheme
                      </p>
                      <select class="border border-dark-gray rounded-xl p-3" v-model="fundingScheme">
                        <option value="">Select your funding scheme</option>
                        <option v-for="(fundingScheme, index) in fundingSchemes" :key="index" :value="fundingScheme">
                          <p>{{ fundingScheme }}</p>
                        </option>
                      </select>
                    </div>
                    <!-- scholarship name -->
                    <base-text-input v-model="scholarName" name="scholarName" label="Scholarship Name" class="pb-5" />
                    <!-- public member -->
                    <div class="flex flex-col w-full pb-5">
                      <p class="text-gray label-text font-sans font-normal mb-">
                        Public Member
                      </p>
                      <select class="border border-dark-gray rounded-xl p-3" v-model="publicMember">
                        <option value="">Select your option</option>
                        <option v-for="(option, index) in yesNoOptions" :key="index" :value="index">
                          <p>{{ option }}</p>
                        </option>
                      </select>
                    </div>
                    <!-- Member category -->
                    <div class="flex flex-col w-full pb-5">
                      <p class="text-gray label-text font-sans font-normal mb-">
                        Membership Category
                      </p>
                      <select class="border border-dark-gray rounded-xl p-3" v-model="membertype">
                        <option value="">Select your membership</option>
                        <option v-for="(member, index) in memberTypes" :key="index" :value="member.name">
                          <p>{{ member.name }}</p>
                        </option>
                      </select>
                    </div>
                    <div v-if="errorMessage" class="p-4 my-2 bg-light-red rounded-lg">
                      <p class="text-red">{{ this.errorMessage }}</p>
                    </div>
                    <BaseButton @click="handleProfileUpdate" class="w-full">
                      <p class="font-snas">
                        Request Profile Update
                      </p>
                    </BaseButton>
                    <!-- /End replace -->
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- reset pass -->
  <TransitionRoot as="template" :show="resetPass">
    <Dialog as="div" class="relative z-50" @close="resetPass = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class=" pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0"
                  enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                  <div class=" absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4"></div>
                </TransitionChild>
                <div class=" flex h-full flex-col overflow-y-scroll  bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6 flex justify-between items-start">
                    <div>
                      <DialogTitle class="text-2xl font-medium font-sans text-blue">Change Password</DialogTitle>
                    </div>
                    <button type="button" class="rounded-md text-white" @click="open = false">
                      <span class="sr-only">Close panel</span>
                      <XMarkIcon class="h-6 w-6 text-black" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="px-6 py-4">
                    <base-text-input v-model="oldPass" name="oldPass" label="Old Password" class="pb-2" />
                    <base-text-input v-model="newPass" name="newPass" label="New Password" class="pb-2" />
                    <base-text-input v-model="confirmPass" name="confirmPass" label="Confirm New Password" class="pb-2" />
                  </div>
                  <div class="mt-auto px-6">
                    <div v-if="errorMessage" class="p-4 my-2 bg-light-red rounded-lg">
                      <p class="text-red">{{ this.errorMessage }}</p>
                    </div>
                    <BaseButton @click="handleChangePassword" class="w-full">
                      <p class="font-snas">
                        Confirm Change
                      </p>
                    </BaseButton>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- /end reset pass -->
</template>

<script>
import BaseButton from "../components/BaseButton.vue";
import { useToast } from "vue-toastification";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import BaseTextInput from "../components/BaseTextInput.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ChevronDownIcon,
  UserIcon,
  ArrowLeftOnRectangleIcon,
  KeyIcon,
} from "@heroicons/vue/24/solid";
import userService from "../services/user.service";
export default {
  name: "NavigarionBar",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    UserIcon,
    ArrowLeftOnRectangleIcon,
    BaseButton,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    BaseTextInput,
    KeyIcon,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isOpen: false,
      showSlideOver: false,
      resetPass: false,
      userData: [],
      errorMessage: "",
      email: "",
      bussEmail: "",
      memberStatus: "",
      imageFile: "",
      paymentImageFile: "",
      imageUrl: "",
      first_name: "",
      last_name: "",
      gender: "",
      title: "",
      dob: "",
      citizenship: "Cambodian",
      PhoneNumber: "",
      bussPhoneNumber: "",
      address: "",
      workPlace: "",
      jobTitle: "",
      otherJob: "",
      countryUniv: "",
      UnivName: "",
      qulification: "",
      major: "",
      dateOfCommencement: "",
      dateOfCompletion: "",
      fundingScheme: "",
      scholarName: "",
      publicMember: "",
      membertype: "",
      paymentMethod: "",
      paymentRecipt: "",
      social: {
        facebook: "",
        linkedin: "",
      },
      genders: ["Male", "Female", "Other"],
      titles: ["Mr", "Mrs", "Miss", "Ms", "Dr", "Prof"],
      qulifications: ["PhD", "Master", "Bachelor", "Diploma"],
      fundingSchemes: ["Scholarship", "Private Funding"],
      memberTypes: [],
      yesNoOptions: ["No", "Yes"],
      paymentMethods: [],
      subscription: "",
      subscriptions: [],
      citizenships: [
        "Afghan",
        "Albanian",
        "Algerian",
        "American",
        "Andorran",
        "Angolan",
        "Antiguans",
        "Argentinean",
        "Armenian",
        "Australian",
        "Austrian",
        "Azerbaijani",
        "Bahamian",
        "Bahraini",
        "Bangladeshi",
        "Barbadian",
        "Barbudans",
        "Batswana",
        "Belarusian",
        "Belgian",
        "Belizean",
        "Beninese",
        "Bhutanese",
        "Bolivian",
        "Bosnian",
        "Brazilian",
        "British",
        "Bruneian",
        "Bulgarian",
        "Burkinabe",
        "Burmese",
        "Burundian",
        "Cambodian",
        "Cameroonian",
        "Canadian",
        "Cape Verdean",
        "Central African",
        "Chadian",
        "Chilean",
        "Chinese",
        "Colombian",
        "Comoran",
        "Congolese",
        "Costa Rican",
        "Croatian",
        "Cuban",
        "Cypriot",
        "Czech",
        "Danish",
        "Djibouti",
        "Dominican",
        "Dutch",
        "East Timorese",
        "Ecuadorean",
        "Egyptian",
        "Emirian",
        "Equatorial Guinean",
        "Eritrean",
        "Estonian",
        "Ethiopian",
        "Fijian",
        "Filipino",
        "Finnish",
        "French",
        "Gabonese",
        "Gambian",
        "Georgian",
        "German",
        "Ghanaian",
        "Greek",
        "Grenadian",
        "Guatemalan",
        "Guinea-Bissauan",
        "Guinean",
        "Guyanese",
        "Haitian",
        "Herzegovinian",
        "Honduran",
        "Hungarian",
        "I-Kiribati",
        "Icelander",
        "Indian",
        "Indonesian",
        "Iranian",
        "Iraqi",
        "Irish",
        "Israeli",
        "Italian",
        "Ivorian",
        "Jamaican",
        "Japanese",
        "Jordanian",
        "Kazakhstani",
        "Kenyan",
        "Kittian and Nevisian",
        "Kuwaiti",
        "Kyrgyz",
        "Laotian",
        "Latvian",
        "Lebanese",
        "Liberian",
        "Libyan",
        "Liechtensteiner",
        "Lithuanian",
        "Luxembourger",
        "Macedonian",
        "Malagasy",
        "Malawian",
        "Malaysian",
        "Maldivan",
        "Malian",
        "Maltese",
        "Marshallese",
        "Mauritanian",
        "Mauritian",
        "Mexican",
        "Micronesian",
        "Moldovan",
        "Monacan",
        "Mongolian",
        "Moroccan",
        "Mosotho",
        "Motswana",
        "Mozambican",
        "Namibian",
        "Nauruan",
        "Nepalese",
        "New Zealander",
        "Nicaraguan",
        "Nigerian",
        "Nigerien",
        "North Korean",
        "Northern Irish",
        "Norwegian",
        "Omani",
        "Pakistani",
        "Palauan",
        "Panamanian",
        "Papua New Guinean",
        "Paraguayan",
        "Peruvian",
        "Polish",
        "Portuguese",
        "Qatari",
        "Romanian",
        "Russian",
        "Rwandan",
        "Saint Lucian",
        "Salvadoran",
        "Samoan",
        "San Marinese",
        "Sao Tomean",
        "Saudi",
        "Scottish",
        "Senegalese",
        "Serbian",
        "Seychellois",
        "Sierra Leonean",
        "Singaporean",
        "Slovakian",
        "Slovenian",
        "Solomon Islander",
        "Somali",
        "South African",
        "South Korean",
        "Spanish",
        "Sri Lankan",
        "Sudanese",
        "Surinamer",
        "Swazi",
        "Swedish",
        "Swiss",
        "Syrian",
        "Taiwanese",
        "Tajik",
        "Tanzanian",
        "Thai",
        "Togolese",
        "Tongan",
        "Trinidadian/Tobagonian",
        "Tunisian",
        "Turkish",
        "Tuvaluan",
        "Ugandan",
        "Ukrainian",
        "Uruguayan",
        "Uzbekistani",
        "Venezuelan",
        "Vietnamese",
        "Welsh",
        "Yemenite",
        "Zambian",
        "Zimbabwean",
      ],
      oldPass: "",
      newPass: "",
      confirmPass: "",
    };
  },
  methods: {
    drawer() {
      this.isOpen = !this.isOpen;
    },
    handleLogOut() {
      this.$store.dispatch("auth/logout");
      this.userInformation = [];
    },
    showUpdateProfile() {
      this.showSlideOver = true;
    },
    handleFileImport() {
      this.$refs.imageFileUploader.click();
    },
    handleFileUpload(event) {
      this.imageFile = event.target.files[0];
      this.imageUrl = URL.createObjectURL(this.imageFile);
    },
    handleProfileUpdate() {
      this.$Progress.start();
      let formData = new FormData();
      let socailData = JSON.stringify(this.social);
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("phone_number", this.phoneNumber);
      formData.append("profile", this.imageFile);
      formData.append("dob", this.dob);
      formData.append("gender", this.gender);
      formData.append("email", this.email);
      formData.append("title", this.title);
      formData.append("business_phone", this.bussPhoneNumber);
      formData.append("business_email", this.bussEmail);
      formData.append("home_address", this.address);
      formData.append("citizenship", this.citizenship);
      formData.append("name_of_organization_workplace", this.workPlace);
      formData.append("job_title", this.jobTitle);
      formData.append("country_of_university_attended", this.countryUniv);
      formData.append("name_of_university_attended", this.UnivName);
      formData.append("highest_qualification", this.qulification);
      formData.append("major", this.major);
      formData.append("date_of_commencement", this.dateOfCommencement);
      formData.append("date_of_completion", this.dateOfCompletion);
      formData.append("funding_scheme", this.fundingScheme);
      formData.append("scholarship_name", this.scholarName);
      formData.append("public_info_to_membership_list", this.publicMember);
      formData.append("membership_category", this.membertype);
      formData.append("social_medias", socailData);

      userService
        .storeProfileUpdate(formData)
        .then(() => {
          this.$Progress.finish();
          this.showSlideOver = false;
          this.errorMessage = "";
          this.toast.success(
            "You have been successfully submitted profile update request form."
          );
        })
        .catch((error) => {
          this.$Progress.fail();
          this.errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        });
    },
    handleGetMemberCategory() {
      userService.getMemberCategory().then((response) => {
        this.memberTypes = response.data.data;
      });
    },
    showResetPassword() {
      this.resetPass = true;
    },
    handleChangePassword() {
      this.$Progress.start();

      let formData = new FormData();
      formData.append("current_password", this.oldPass);
      formData.append("new_password", this.newPass);
      formData.append("confirm_password", this.confirmPass);

      userService.storeChangePassword(formData).then(() => {
        this.$Progress.finish();
        this.resetPass = false;
        this.errorMessage = "";
        this.oldPass = "";
        this.newPass = "";
        this.confirmPass = "";
        this.toast.success("Password changed successfully.");
      }).catch((error) => {
        this.$Progress.fail();
        this.errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      });
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    headerLogo() {
      return this.$store.getters.headerLogo;
    },
  },
  created() {
    if (this.loggedIn) {
      this.userData = localStorage.getItem("user");
      this.userData = JSON.parse(this.userData);
      this.userData = this.userData.data;
      this.imageUrl = this.userData.profile;
      this.first_name = this.userData.first_name;
      this.last_name = this.userData.last_name;
      this.gender = this.userData.gender;
      this.title = this.userData.title;
      this.dob = this.userData.dob.substring(0, 10);
      this.citizenship = this.userData.citizenship;
      this.phoneNumber = this.userData.phone_number;
      if (this.userData.business_phone) {
        this.bussPhoneNumber = this.userData.business_phone;
      }
      this.email = this.userData.email;
      if (this.userData.business_email) {
        this.bussEmail = this.userData.business_email;
      }
      this.address = this.userData.address;
      this.workPlace = this.userData.name_of_organization_workplace;
      this.jobTitle = this.userData.job_title;
      this.otherJob = this.userData.other_job;
      if (this.userData.social_medias && this.userData.social_medias.length > 0) {
        this.social.facebook = this.userData.social_medias[0].url;
      }
      if (this.userData.social_medias && this.userData.social_medias.length > 0) {
        this.social.twitter = this.userData.social_medias[1].url;
      }
      this.countryUniv = this.userData.country_of_university_attended;
      this.UnivName = this.userData.name_of_university_attended;
      this.major = this.userData.major;
      this.dateOfCommencement = this.userData.date_of_commencement.substring(
        0,
        10
      );
      this.dateOfCompletion = this.userData.date_of_completion.substring(0, 10);
      this.fundingScheme = this.userData.funding_scheme;
      this.scholarName = this.userData.scholarship_name;
      if (this.userData.public_info_to_membership_list == true) {
        this.publicMember = 1;
      } else {
        this.publicMember = 0;
      }
      this.membertype = this.userData.membership_category;
      this.qulification = this.userData.highest_qualification;
    }
    this.handleGetMemberCategory();
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (process.client) {
          if (isOpen) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      },
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27 && this.isOpen) this.isOpen = false;
    });
  },
};
</script>

<style scoped>
.square_img {
  aspect-ratio: 1/1;
}
</style>