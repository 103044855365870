import axios from "axios";
import authHeader from "./auth-header"

let API_URL = process.env.VUE_APP_BASE_API_URL

class UserService {
    // GET methods
    getFindMoreCard() {
        return axios.get(API_URL + 'category', { headers: authHeader() })
    }
    getAboutUs() {
        return axios.get(API_URL + 'pages?slug_url=about_us', { headers: authHeader() })
    }
    getContent(params) {
        return axios.get(API_URL + 'contents', { params: params, headers: authHeader() })
    }
    getContentDetail(slugUrl) {
        return axios.get(API_URL + 'contents/?slug_url=' + slugUrl, { headers: authHeader() })
    }
    getAACProfile() {
        return axios.get(API_URL + 'aaac_profile')
    }
    getPage(slugUrl) {
        return axios.get(API_URL + 'pages?slug_url=' + slugUrl, { headers: authHeader() })
    }
    getLeadershipData() {
        return axios.get(API_URL + 'leader_ship_section', { headers: authHeader() })
    }
    getMember(params) {
        return axios.get(API_URL + 'member_directory', {
            params: params,
            headers: authHeader()
        })
    }
    getPaymentList() {
        return axios.get(API_URL + 'payments', { headers: authHeader() })
    }
    getSubscriptions() {
        return axios.get(API_URL + 'subscriptions', { headers: authHeader() })
    }
    getMemberCategory() {
        return axios.get(API_URL + 'member_category', { headers: authHeader() })
    }


    // POST methods
    storeComment(formData) {
        return axios.post(API_URL + 'contact_form', formData, { headers: authHeader() })
    }

    register(formData) {
        return axios.post(API_URL + 'register', formData, { headers: authHeader() })
    }

    storeProfileUpdate(formData) {
        return axios.post(API_URL + 'request_change_info', formData, { headers: authHeader() })
    }

    storeChangePassword(formData) {
        return axios.post(API_URL + 'change_password', formData, { headers: authHeader() })
    }

    storePlayerId(formData) {
        return axios.post(API_URL + 'store_player_id', formData, { headers: authHeader() })
    }

}

export default new UserService