<template>
  <div class="container max-w-7xl mx-auto">
    <div class="m-4">
      <!-- Registration form and payments -->
      <div>
        <h1 class="header text-2xl text-blue text-center">
          Simple Step to Become a Member of AAA-C
        </h1>
        <div class="md:flex my-4 space-y-4">
          <div class="md:w-4/12 p-4 mx-2 bg-light-gray rounded-xl">
            <div class="flex items-start">
              <div class="w-11 px-1.5 py-1">
                <p class="text-4xl text-blue font-sans font-bold text-white">
                  1
                </p>
              </div>
              <p class="text-lg text-black font-sans text-left ml-2">
                First, request for membership registration here
              </p>
            </div>
            <BaseButton @click="handleRegister" class="my-4">
              <p class="text-white font-sans">
                Request Membership
              </p>
            </BaseButton>
          </div>
          <div class="md:w-8/12 mx-2">
            <div class="flex items-start">
              <div class="w-11 px-2 py-1">
                <p class="text-4xl text-blue font-sans font-bold text-white">
                  2
                </p>
              </div>
              <p class="text-lg text-black font-sans text-left ml-2">
                Make a payment for the membership fee of 15USD for annual
                membership or 150 USD for lifetime membership. The payment can
                be made at either:
              </p>
            </div>
            <div class="md:flex">
              <div v-for="payment in paymentMethods" :key="payment.id"
                class="md:w-4/12 p-4 mt-4 md:mx-2 bg-light-gray rounded-xl">
                <div>
                  <h1 class="text-2xl text-blue font-sans text-left">
                    {{ payment.bank_name }}
                  </h1>
                  <div class="my-2 font-sans text-left">
                    <p>Account Number: {{ payment.account_number }}</p>
                    <p>Account Holder: {{ payment.account_holder }}</p>
                    <img class="w-full py-2" :src="payment.image_qr" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Membership fee -->
      <div class="justify-items-center">
        <h1 class="header text-2xl text-blue text-center">Membership Fee</h1>
        <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-1 my-4">
          <div v-for="subscription in subscriptions" :key="subscription.id"
            class="bg-white px-4 py-8 my-2 mx-2 shadow-md border border-light-gray rounded-2xl">
            <p class="text-blue md:text-2xl text-xl font-sans">{{ subscription.name }}</p>
            <h1 class="md:text-6xl text-4xl text-blue font-sans font-bold my-4">
              $ {{ subscription.price }}
            </h1>
            <p class="text-gray text-lg font-sans">
              {{ subscription.description }}
            </p>
          </div>
        </div>
      </div>
      <!-- Membership type -->
      <div class="justify-items-center">
        <h1 class="header text-2xl text-blue text-center">
          Membership Categories (based on the revised charter)
        </h1>
        <div class="md:flex md:flex-row justify-center">
          <div v-for="(memberType, index) in memberTypes" :key="index" class="
              bg-light-gray
              text-left
              px-4
              py-4
              my-4
              mx-2
              rounded-2xl
              md:w-1/3
            ">
            <p class="text-blue text-2xl font-sans">{{ memberType.name }}</p>
            <p class="text-gray text-lg font-sans">
              {{ memberType.description }}
            </p>
          </div>
        </div>
      </div>
      <!-- Slide over -->
      <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-50" @close="open = false">
          <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
            leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
              <div class="
                  pointer-events-none
                  fixed
                  inset-y-0
                  right-0
                  flex
                  max-w-full
                  pl-10
                ">
                <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enter-from="translate-x-full" enter-to="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                  leave-to="translate-x-full">
                  <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                    <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0"
                      enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100"
                      leave-to="opacity-0">
                      <div class="
                          absolute
                          top-0
                          left-0
                          -ml-8
                          flex
                          pt-4
                          pr-2
                          sm:-ml-10 sm:pr-4
                        "></div>
                    </TransitionChild>
                    <div class="
                        flex
                        h-full
                        flex-col
                        overflow-y-scroll
                        bg-white
                        py-6
                        shadow-xl
                      ">
                      <div class="px-4 sm:px-6 flex justify-between items-start">
                        <div>
                          <DialogTitle class="text-2xl font-medium font-sans text-blue">Request Membership</DialogTitle>
                        </div>
                        <button type="button" class="rounded-md text-white" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6 text-black" aria-hidden="true" />
                        </button>
                      </div>
                      <div class="relative mt-6 flex-1 px-4 sm:px-6">
                        <!-- profile image -->
                        <div @click="handleFileImport" class="group py-4 cursor-pointer">
                          <!-- hiddden image file input field that triger when profile image container clicked -->
                          <input ref="imageFileUploader" class="hidden" type="file" name="profileImage"
                            id="profileImage" @change="handleFileUpload($event)" />
                          <div class="flex justify-center">
                            <img class="
                                w-1/4
                                square_img
                                object-cover
                                rounded-full
                                mb-2
                              " :src="imageUrl" alt="" />
                          </div>
                          <p class="
                              cursor-pointer
                              text-center text-lg
                              font-sans
                              text-blue
                              group-hover:underline
                            ">
                            Change profile image
                          </p>
                        </div>
                        <div>
                          <p class="text-gray font-sans font-light text-lg mb-2">
                            About you
                          </p>
                        </div>
                        <!-- first name and last name -->
                        <div class="flex pb-4">
                          <base-text-input v-model="first_name" name="first_name" label="First Name"
                            class="w-1/2 pr-2" />
                          <base-text-input v-model="last_name" name="last_name" label="Last Name" class="w-1/2 pl-2" />
                        </div>
                        <!-- gender and title -->
                        <div class="flex pb-4">
                          <div class="flex flex-col w-1/2 mr-2">
                            <p class="
                                text-gray
                                label-text
                                font-sans font-normal
                                mb-1
                              ">
                              Gender
                            </p>
                            <select class="border border-dark-gray rounded-xl p-3" v-model="gender">
                              <option value="">Select gender</option>
                              <option v-for="(gender, index) in genders" :key="index" :value="index">
                                <p>{{ gender }}</p>
                              </option>
                            </select>
                          </div>
                          <div class="flex flex-col w-1/2 ml-2">
                            <p class="
                                text-gray
                                label-text
                                font-sans font-normal
                                mb-1
                              ">
                              Title
                            </p>
                            <select class="border border-dark-gray rounded-xl p-3" v-model="title">
                              <option value="">Select your title</option>
                              <option v-for="(title, index) in titles" :key="index" :value="title">
                                <p>{{ title }}</p>
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- date of birth -->
                        <base-text-input v-model="dob" type="date" name="dob" label="Date of Birht" class="pb-5" />
                        <!-- citizenship -->
                        <div class="flex flex-col w-full pb-5">
                          <p class="
                              text-gray
                              label-text
                              font-sans font-normal
                              mb-
                            ">
                            Citizenship
                          </p>
                          <select class="border border-dark-gray rounded-xl p-3" v-model="citizenship">
                            <option value="">Select your citizenship</option>
                            <option v-for="(citizenship, index) in citizenships" :key="index" :value="citizenship">
                              <p>{{ citizenship }}</p>
                            </option>
                          </select>
                        </div>
                        <!-- phone number -->
                        <base-text-input v-model="phoneNumber" name="phoneNumber" label="Phone Number" class="pb-5" />
                        <!-- buss phone number -->
                        <base-text-input v-model="bussPhoneNumber" name="bussPhoneNumber"
                          label="Business Phone (Alternative)" class="pb-5" />
                        <!-- email -->
                        <base-text-input v-model="email" name="email" label="Email" class="pb-5" />
                        <!-- buss email -->
                        <base-text-input v-model="bussEmail" name="bussEmail" label="Business Email (Alternative)"
                          class="pb-5" />
                        <!-- password -->
                        <base-text-input v-model="pass" name="pass" label="Password" class="pb-5" />
                        <!-- address -->
                        <base-text-input v-model="address" name="address" label="Address" class="pb-5" />
                        <!-- work place -->
                        <base-text-input v-model="workPlace" name="workPlace" label="Name of Oraganization/Workplace"
                          class="pb-5" />
                        <!-- job tile -->
                        <base-text-input v-model="jobTitle" name="jobTitle" label="Job Title" class="pb-5" />
                        <!-- orther Job -->
                        <base-text-input v-model="otherJob" name="otherJob" label="Other Job (if any)" class="pb-5" />
                        <div>
                          <p class="text-gray font-sans font-light text-lg mb-2">
                            Social Media
                          </p>
                        </div>
                        <!-- facebook -->
                        <base-text-input v-model="social.facebook" name="facebook" label="Facebook" class="pb-5" />
                        <!-- orther Job -->
                        <base-text-input v-model="social.linkedin" name="lnkedin" label="linkedIn" class="pb-5" />
                        <div>
                          <p class="text-gray font-sans font-light text-lg mb-2">
                            Education Background
                          </p>
                        </div>
                        <!-- university country -->
                        <base-text-input v-model="countryUniv" name="contryUniv" label="Country of University Attended"
                          class="pb-5" />
                        <!-- university name -->
                        <base-text-input v-model="UnivName" name="UnivName" label="Name of University Attended"
                          class="pb-5" />
                        <!-- Qualification -->
                        <div class="flex flex-col w-full pb-5">
                          <p class="
                              text-gray
                              label-text
                              font-sans font-normal
                              mb-
                            ">
                            Qualification
                          </p>
                          <select class="border border-dark-gray rounded-xl p-3" v-model="qulification">
                            <option value="">Select your qualification</option>
                            <option v-for="(qualification, index) in qulifications" :key="index" :value="qualification">
                              <p>{{ qualification }}</p>
                            </option>
                          </select>
                        </div>
                        <!-- major name -->
                        <base-text-input v-model="major" name="major" label="Major" class="pb-5" />
                        <!-- date of Commencement -->
                        <base-text-input v-model="dateOfCommencement" type="date" name="dateofCommencement"
                          label="Date of Commencement" class="pb-5" />
                        <!-- date of complition -->
                        <base-text-input v-model="dateOfCompletion" type="date" name="dateOfCompletion"
                          label="Date of Completion" class="pb-5" />
                        <!-- Funding -->
                        <div class="flex flex-col w-full pb-5">
                          <p class="
                              text-gray
                              label-text
                              font-sans font-normal
                              mb-
                            ">
                            Funding Scheme
                          </p>
                          <select class="border border-dark-gray rounded-xl p-3" v-model="fundingScheme">
                            <option value="">Select your funding scheme</option>
                            <option v-for="(fundingScheme, index) in fundingSchemes" :key="index"
                              :value="fundingScheme">
                              <p>{{ fundingScheme }}</p>
                            </option>
                          </select>
                        </div>
                        <!-- scholarship name -->
                        <base-text-input v-model="scholarName" name="scholarName" label="Scholarship Name"
                          class="pb-5" />
                        <!-- public member -->
                        <div class="flex flex-col w-full pb-5">
                          <p class="
                              text-gray
                              label-text
                              font-sans font-normal
                              mb-
                            ">
                            Public Member
                          </p>
                          <select class="border border-dark-gray rounded-xl p-3" v-model="publicMember">
                            <option value="">Select your option</option>
                            <option v-for="(option, index) in yesNoOptions" :key="index" :value="index">
                              <p>{{ option }}</p>
                            </option>
                          </select>
                        </div>
                        <div>
                          <p class="text-gray font-sans font-light text-lg mb-2">
                            Payment
                          </p>
                        </div>
                        <!-- Member category -->
                        <div class="flex flex-col w-full pb-5">
                          <p class="
                              text-gray
                              label-text
                              font-sans font-normal
                              mb-
                            ">
                            Membership Category
                          </p>
                          <select class="border border-dark-gray rounded-xl p-3" v-model="membertype">
                            <option value="">Select your membership</option>
<!--                            <option v-for="(member, index) in memberTypes" :key="index" :value="member[index].name">-->
<!--                              <p>{{ member[index].name }}</p>-->
<!--                            </option>-->
                            <option v-for="member in memberTypes" :key="member" :value="member.name">
                              <p>{{ member.name }}</p>
                            </option>
                          </select>
                        </div>
                        <!-- Subscription -->
                        <div class="flex flex-col w-full pb-5">
                          <p class="
                              text-gray
                              label-text
                              font-sans font-normal
                              mb-
                            ">
                            Subscription
                          </p>
                          <select class="border border-dark-gray rounded-xl p-3" v-model="subscription">
                            <option value="">Select your subscription</option>
                            <option v-for="subscription in subscriptions" :key="subscription.id"
                              :value="subscription.type">
                              <p>{{ subscription.name }}</p>
                            </option>
                          </select>
                        </div>
                        <!-- payment method -->
                        <div class="flex flex-col w-full pb-5">
                          <p class="
                              text-gray
                              label-text
                              font-sans font-normal
                              mb-
                            ">
                            Payment Method
                          </p>
                          <select class="border border-dark-gray rounded-xl p-3" v-model="paymentMethod">
                            <option value="">Select your payment method</option>
                            <option v-for="payment in paymentMethods" :key="payment.id" :value="payment.id">
                              <p>{{ payment.bank_name }}</p>
                            </option>
                          </select>
                        </div>
                        <!-- payment reciept image -->
                        <div @click="handleReciptImport" class="group py-4 cursor-pointer">
                          <p class="text-gray font-sans mb-2">
                            Payment Receipt
                          </p>
                          <!-- hiddden image file input field that triger when profile image container clicked -->
                          <input ref="RecieptUploader" class="hidden" type="file" name="recieptImage" id="recieptImage"
                            @change="handleRecieptUpload($event)" />
                          <div class="flex justify-center">
                            <img v-if="paymentRecipt" class="w-full object-cover rounded-md mb-2" :src="paymentRecipt"
                              alt="" />
                            <div v-else class="
                                w-full
                                px-8
                                py-44
                                rounded-lg
                                bg-light-gray
                                flex
                                justify-center
                                content-center
                              ">
                              <div class="flex flex-col items-center">
                                <PhotoIcon class="h-12 w-12 text-gray" aria-hidden="true" />
                                <p class="text-gray">Upload Payment Receipt</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="errorMessage" class="p-4 my-2 bg-light-red rounded-lg">
                          <p class="text-red">{{ this.errorMessage }}</p>
                        </div>
                        <BaseButton @click="handleSubmit" class="w-full">
                          <p class="font-snas">Submit</p>
                        </BaseButton>

                        <!-- /End replace -->
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </div>
</template>

<script>
import BaseButton from "../components/BaseButton.vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon, PhotoIcon } from "@heroicons/vue/24/outline";
import BaseTextInput from "../components/BaseTextInput.vue";
import userService from "../services/user.service";
import { useToast } from "vue-toastification";

export default {
  name: "Become Member",
  components: {
    BaseButton,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    BaseTextInput,
    PhotoIcon,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      open: false,
      errorMessage: "",
      email: "",
      bussEmail: "",
      pass: "",
      memberStatus: "",
      imageFile: "",
      paymentImageFile: "",
      imageUrl:
        "https://i0.wp.com/goodguidancestories.org/wp-content/uploads/2020/05/Portrait_Placeholder.png?resize=600%2C600&ssl=1",
      first_name: "",
      last_name: "",
      gender: "",
      title: "",
      dob: "",
      citizenship: "",
      PhoneNumber: "",
      bussPhoneNumber: "",
      address: "",
      workPlace: "",
      jobTitle: "",
      otherJob: "",
      countryUniv: "",
      UnivName: "",
      qulification: "",
      major: "",
      dateOfCommencement: "",
      dateOfCompletion: "",
      fundingScheme: "",
      scholarName: "",
      publicMember: "",
      membertype: "",
      paymentMethod: "",
      paymentRecipt: "",
      social: {
        facebook: "",
        linkedin: "",
      },
      genders: ["Male", "Female", "Other"],
      titles: ["Mr", "Mrs", "Miss", "Ms", "Dr", "Prof"],
      qulifications: ["PhD", "Master", "Bachelor", "Diploma"],
      fundingSchemes: ["Scholarship", "Private Funding"],
      membertypes: ["General Member", "Associate Member"],
      yesNoOptions: ["No", "Yes"],
      paymentMethods: [],
      subscription: "",
      subscriptions: [],
      citizenships: [
        "Afghan",
        "Albanian",
        "Algerian",
        "American",
        "Andorran",
        "Angolan",
        "Antiguans",
        "Argentinean",
        "Armenian",
        "Australian",
        "Austrian",
        "Azerbaijani",
        "Bahamian",
        "Bahraini",
        "Bangladeshi",
        "Barbadian",
        "Barbudans",
        "Batswana",
        "Belarusian",
        "Belgian",
        "Belizean",
        "Beninese",
        "Bhutanese",
        "Bolivian",
        "Bosnian",
        "Brazilian",
        "British",
        "Bruneian",
        "Bulgarian",
        "Burkinabe",
        "Burmese",
        "Burundian",
        "Cambodian",
        "Cameroonian",
        "Canadian",
        "Cape Verdean",
        "Central African",
        "Chadian",
        "Chilean",
        "Chinese",
        "Colombian",
        "Comoran",
        "Congolese",
        "Costa Rican",
        "Croatian",
        "Cuban",
        "Cypriot",
        "Czech",
        "Danish",
        "Djibouti",
        "Dominican",
        "Dutch",
        "East Timorese",
        "Ecuadorean",
        "Egyptian",
        "Emirian",
        "Equatorial Guinean",
        "Eritrean",
        "Estonian",
        "Ethiopian",
        "Fijian",
        "Filipino",
        "Finnish",
        "French",
        "Gabonese",
        "Gambian",
        "Georgian",
        "German",
        "Ghanaian",
        "Greek",
        "Grenadian",
        "Guatemalan",
        "Guinea-Bissauan",
        "Guinean",
        "Guyanese",
        "Haitian",
        "Herzegovinian",
        "Honduran",
        "Hungarian",
        "I-Kiribati",
        "Icelander",
        "Indian",
        "Indonesian",
        "Iranian",
        "Iraqi",
        "Irish",
        "Israeli",
        "Italian",
        "Ivorian",
        "Jamaican",
        "Japanese",
        "Jordanian",
        "Kazakhstani",
        "Kenyan",
        "Kittian and Nevisian",
        "Kuwaiti",
        "Kyrgyz",
        "Laotian",
        "Latvian",
        "Lebanese",
        "Liberian",
        "Libyan",
        "Liechtensteiner",
        "Lithuanian",
        "Luxembourger",
        "Macedonian",
        "Malagasy",
        "Malawian",
        "Malaysian",
        "Maldivan",
        "Malian",
        "Maltese",
        "Marshallese",
        "Mauritanian",
        "Mauritian",
        "Mexican",
        "Micronesian",
        "Moldovan",
        "Monacan",
        "Mongolian",
        "Moroccan",
        "Mosotho",
        "Motswana",
        "Mozambican",
        "Namibian",
        "Nauruan",
        "Nepalese",
        "New Zealander",
        "Nicaraguan",
        "Nigerian",
        "Nigerien",
        "North Korean",
        "Northern Irish",
        "Norwegian",
        "Omani",
        "Pakistani",
        "Palauan",
        "Panamanian",
        "Papua New Guinean",
        "Paraguayan",
        "Peruvian",
        "Polish",
        "Portuguese",
        "Qatari",
        "Romanian",
        "Russian",
        "Rwandan",
        "Saint Lucian",
        "Salvadoran",
        "Samoan",
        "San Marinese",
        "Sao Tomean",
        "Saudi",
        "Scottish",
        "Senegalese",
        "Serbian",
        "Seychellois",
        "Sierra Leonean",
        "Singaporean",
        "Slovakian",
        "Slovenian",
        "Solomon Islander",
        "Somali",
        "South African",
        "South Korean",
        "Spanish",
        "Sri Lankan",
        "Sudanese",
        "Surinamer",
        "Swazi",
        "Swedish",
        "Swiss",
        "Syrian",
        "Taiwanese",
        "Tajik",
        "Tanzanian",
        "Thai",
        "Togolese",
        "Tongan",
        "Trinidadian/Tobagonian",
        "Tunisian",
        "Turkish",
        "Tuvaluan",
        "Ugandan",
        "Ukrainian",
        "Uruguayan",
        "Uzbekistani",
        "Venezuelan",
        "Vietnamese",
        "Welsh",
        "Yemenite",
        "Zambian",
        "Zimbabwean",
      ],
      memberTypes: [],
    };
  },
  methods: {
    handleRegister() {
      this.open = true;
    },
    handleFileImport() {
      this.$refs.imageFileUploader.click();
    },
    handleReciptImport() {
      this.$refs.RecieptUploader.click();
    },
    handleFileUpload(event) {
      this.imageFile = event.target.files[0];
      this.imageUrl = URL.createObjectURL(this.imageFile);
    },
    handleRecieptUpload(event) {
      this.paymentImageFile = event.target.files[0];
      this.paymentRecipt = URL.createObjectURL(this.paymentImageFile);
    },
    handleSubmit() {
      this.$Progress.start();
      let formData = new FormData();
      let socailData = JSON.stringify(this.social);
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("phone_number", this.phoneNumber);
      formData.append("profile", this.imageFile);
      formData.append("dob", this.dob);
      formData.append("gender", this.gender);
      formData.append("email", this.email);
      formData.append("password", this.pass);
      formData.append("subscription_id", this.subscription);
      formData.append("payment_id", this.paymentMethod);
      formData.append("attachment_payment_file", this.paymentImageFile);
      formData.append("title", this.title);
      formData.append("business_phone", this.bussPhoneNumber);
      formData.append("business_email", this.bussEmail);
      formData.append("home_address", this.address);
      formData.append("citizenship", this.citizenship);
      formData.append("name_of_organization_workplace", this.workPlace);
      formData.append("job_title", this.jobTitle);
      formData.append("country_of_university_attended", this.countryUniv);
      formData.append("name_of_university_attended", this.UnivName);
      formData.append("highest_qualification", this.qulification);
      formData.append("major", this.major);
      formData.append("date_of_commencement", this.dateOfCommencement);
      formData.append("date_of_completion", this.dateOfCompletion);
      formData.append("funding_scheme", this.fundingScheme);
      formData.append("scholarship_name", this.scholarName);
      formData.append("public_info_to_membership_list", this.publicMember);
      formData.append("membership_category", this.membertype);
      formData.append("social_medias", socailData);

      userService
        .register(formData)
        .then(() => {
          this.$Progress.finish();
          this.open = false;
          this.errorMessage = "";
          this.toast.success(
            "You have been successfully submited request member form!"
          );
        })
        .catch((error) => {
          this.$Progress.fail();
          this.errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        });
    },
    getPaymentList() {
      userService.getPaymentList().then((response) => {
        this.paymentMethods = response.data.data;
      });
    },
    getSubscription() {
      userService.getSubscriptions().then((response) => {
        this.subscriptions = response.data.data;
        this.$Progress.finish();
      });
    },
    getMemberCateogries() {
      userService.getMemberCategory().then((response) => {
        this.memberTypes = response.data.data;
        console.log(response.data.data)
      })
    },
  },
  mounted() {
    this.$Progress.start();
    this.getPaymentList();
    this.getSubscription();
    this.getMemberCateogries();
  },
};
</script>

<style scoped>
.square_img {
  aspect-ratio: 1/1;
}
</style>