<template>
  <div class="container max-w-7xl mx-auto">
    <!-- slide show images container -->
    <swiper
      :slides-per-view="1"
      :speed="800"
      :loop="true"
      :autoplay="autoplay"
      :modules="modules"
    >
      <swiper-slide v-for="slide in slidesImage" :key="slide.id">
        <div class="relative">
          <div
            class="
              absolute
              w-5/12
              p-4
              bg-black bg-opacity-50
              rounded-xl
              text-white text-left
              bottom-5
              left-5
              hidden
              md:block
            "
          >
            <p class="sm:text-2xl text-lg mb-2 truncate-2-lines font-sans">
              {{ slide.title }}
            </p>
            <p class="text-sm hidden sm:block truncate-2-lines font-sans">
              {{ slide.content }}
            </p>
            <div
              class="
                flex
                mt-2
                items-center
                text-light-gray
                hover:text-white hover:underline
                cursor-pointer
              "
            >
              <p @click="pushToContent(slide.slug_url)" class="text-sm font-sans">
                See more
              </p>
              <ChevronRightIcon
                class="mr-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            </div>
          </div>
          <img
            class="w-full sm:aspect-video object-cover carousel-img"
            :src="slide.thumbnail_image"
            alt=""
          />
        </div>
        <div class="w-full p-2 bg-blue text-white text-left md:hidden block">
          <p class="sm:text-2xl text-lg mb-2 truncate-1-lines font-sans">
            {{ slide.title }}
          </p>
          <div
            class="
              flex
              mt-2
              items-center
              text-light-gray
              hover:text-white hover:underline
              cursor-pointer
            "
          >
            <p @click="pushToContent(slide.id)" class="text-sm font-sans">
              See more
            </p>
            <ChevronRightIcon
              class="mr-2 h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <!-- about container -->
    <div class="md:flex md:px-44 px-6 md:text-left py-8">
      <!-- about us text -->
      <div class="md:w-8/12 w-full md:pr-12">
        <h1 class="text-2xl pb-4">
          Welcome to the Australian Alumni Association of Cambodia
        </h1>
        <p class="pb-6">
          The Australian Alumni Association of Cambodia (AAA-C) is pleased to
          cordially call for Candidates for the upcoming Board Election for the
          2020-2022 Mandate.
        </p>
        <BaseButton @click="buttonClick()" class="w-auto"
          >Learn More</BaseButton
        >
      </div>
      <!-- about us image -->
      <div class="md:w-4/12 w-full md:my-0 my-8">
        <img
          class="rounded-md mx-0 rectangle_img"
          :src="profileData.cover"
          alt=""
        />
      </div>
    </div>
    <!-- find more container -->
    <div class="md:p-8 p-6 md:mx-0 mb-4 bg-light-blue md:rounded-xl text-left">
      <p class="text-2xl">Find More</p>
      <!-- find more card -->
      <div
        class="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-2 md:gap-4"
      >
        <div
          @click="this.$router.push('/news')"
          class="group flex flex-col bg-white my-4 rounded-xl cursor-pointer"
        >
          <img
            class="w-full rounded-t-xl rectangle_img object-cover"
            :src="NewsCard.image"
            alt=""
          />
          <div class="p-4 text-left content-between">
            <h1 class="text-2xl group-hover:text-blue group-hover:underline">
              {{ NewsCard.name }}
            </h1>
            <p class="text-sm text-gray group-hover:text-black">
              {{ NewsCard.description }}
            </p>
          </div>
          <p class="text-blue hover:underline mt-auto px-4 pb-2">Read More</p>
        </div>
        <div
          @click="this.$router.push('/events')"
          class="group flex flex-col bg-white my-4 rounded-xl cursor-pointer"
        >
          <img
            class="w-full rounded-t-xl rectangle_img object-cover"
            :src="EventCard.image"
            alt=""
          />
          <div class="p-4 text-left content-between">
            <h1 class="text-2xl group-hover:text-blue group-hover:underline">
              {{ EventCard.name }}
            </h1>
            <p class="text-sm text-gray group-hover:text-black">
              {{ EventCard.description }}
            </p>
          </div>
          <p class="text-blue hover:underline mt-auto px-4 pb-2">Read More</p>
        </div>
        <div
          @click="this.$router.push('/opportunities')"
          class="group flex flex-col bg-white my-4 rounded-xl cursor-pointer"
        >
          <img
            class="w-full rounded-t-xl rectangle_img object-cover"
            :src="OpportunityCard.image"
            alt=""
          />
          <div class="p-4 text-left content-between">
            <h1 class="text-2xl group-hover:text-blue group-hover:underline">
              {{ OpportunityCard.name }}
            </h1>
            <p class="text-sm text-gray group-hover:text-black">
              {{ OpportunityCard.description }}
            </p>
          </div>
          <p class="text-blue hover:underline mt-auto px-4 pb-2">Read More</p>
        </div>
        <div
          @click="this.$router.push('/member_directory')"
          class="group flex flex-col bg-white my-4 rounded-xl cursor-pointer"
        >
          <img
            class="w-full rounded-t-xl rectangle_img object-cover"
            :src="memberCard.image"
            alt=""
          />
          <div class="p-4 text-left content-between">
            <h1 class="text-2xl group-hover:text-blue group-hover:underline">
              {{ memberCard.name }}
            </h1>
            <p class="text-sm text-gray group-hover:text-black">
              {{ memberCard.description }}
            </p>
          </div>
          <p class="text-blue hover:underline mt-auto px-4 pb-2">Read More</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import { ChevronRightIcon } from "@heroicons/vue/24/solid";
import BaseButton from "../components/BaseButton.vue";
import userService from "../services/user.service";

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    ChevronRightIcon,
    BaseButton,
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  data() {
    return {
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      slidesImage: [],
      NewsCard: [],
      EventCard: [],
      OpportunityCard: [],
      memberCard: [],
      profileData: [],
      params: new URLSearchParams(),
    };
  },
  created() {
    this.$Progress.start();
    this.params.set('type', 1);
    this.params.set('count', 5);
    userService.getContent(this.params).then((response) => {
      this.slidesImage = response.data.data;
    });
    userService.getFindMoreCard().then((response) => {
      this.NewsCard = response.data.data[0];
      this.EventCard = response.data.data[1];
      this.OpportunityCard = response.data.data[2];
      this.memberCard = response.data.data[3];
    });
    userService.getAACProfile().then((response) => {
      this.profileData = response.data.data;
    });
  },
  mounted() {
    this.$Progress.finish();
  },
  methods: {
    buttonClick() {
      this.$router.push("/about");
    },
    pushToContent(slug_url) {
      this.$router.push("/content/" + slug_url);
    },
  },
};
</script>

<style scoped>
.carousel-img {
  aspect-ratio: 21/9;
}

.rectangle_img {
  aspect-ratio: 16/9;
}

.truncate-2-lines {
  max-height: 3.6em;
  /* double the size of line-height */
  line-height: 1.8em;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.truncate-1-lines {
  max-height: 1.8em;
  /* double the size of line-height */
  line-height: 1.8em;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
</style>
